import request from '../utils/request';

//获取全部记录
export const FindTolLog = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Detail/FindTolLog',
        method: 'get',
        params: query
    });
};

//获取全部记录（状态）
export const FindInShowLog = query => {
    return request({
        url: process.env.VUE_APP_URL + 'Website/Detail/FindInShowLog',
        method: 'get',
        params: query
    });
};
