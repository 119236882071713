<template>
  <div class="Detail">
    <NavToPush></NavToPush>
    <UserInfo :UserInfo="UserInfo"></UserInfo>
    <NavClass :TClass="TClass"></NavClass>
    <UserData :Data="Data"></UserData>
  </div>
</template>

<script>
import UserInfo from './Com/UserInfo.vue'
import NavToPush from './Com/NavToPush.vue'
import NavClass from './Com/NavClass.vue'
import UserData from './Com/UserData.vue'
import {FindTolLog, FindInShowLog} from "@/api/Detail";
import {Notify} from "vant";

export default {
  data() {
    return {
      UserInfo: {},
      TClass: '全部记录',
      Data: []
    }
  },
  components: {
    UserInfo,
    NavToPush,
    NavClass,
    UserData
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let info = this.$store.state.user.info
      this.UserInfo = info
      this.getToldata()
    },
    getToldata() {
      let info = this.$store.state.user.info
      let User_ID = info.id

      FindTolLog({User_ID}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          this.settime(data)
        } else {
          Notify({type: "danger", message: '服务器出错'});
        }
      })
    },
    getInShow(InShow) {
      let info = this.$store.state.user.info
      let User_ID = info.id

      FindInShowLog({User_ID, InShow}).then(res => {
        if (res.errorCode == 200) {
          let data = res.payload
          this.settime(data)
        } else {
          Notify({type: "danger", message: '服务器出错'});
        }
      })
    },
    InClass() {
      let TClass = this.TClass
      if (TClass == '全部记录') {
        this.getToldata()
      } else if (TClass == '获取记录') {
        this.getInShow('新增')
      } else {
        this.getInShow('减少')
      }
    },
    switchClass(value) {
      this.TClass = value
      this.InClass()
    },
    settime(data) {
      this.Data = []
      for (let i in data) {
        let time = data[i].Time
        let day = new Date(time);
        data[i].Time = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()
        let timeHours = day.getHours(),//时
            timeMinutes = day.getMinutes();  //分

        if (timeHours < 10) {
          timeHours = '0' + timeHours
        }
        if (timeMinutes < 10) {
          timeMinutes = '0' + timeMinutes
        }

        let times = timeHours + ':' + timeMinutes
        data[i].Times = times

        let Title = data[i].Title
        let Class = data[i].Class

        if (Title && Title.length >= 10) {
          data[i].Title = Title.slice(0, 10) + '...'
        }
        if (Class && Class.length >= 10) {
          data[i].Class = Class.slice(0, 10) + '...'
        }

      }
      this.Data = data;
    }
  }
}
</script>

<style scoped lang="scss">
.Detail {
  width: 100%;
  height: 100%;
  background-color: #000;
}
</style>