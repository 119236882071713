<template>
  <div class="UserData" :style="listheight">
    <div class="data" v-for="(item,index) in Data" :key="index">
      <div class="title">
        <span v-if="item.InShow=='新增'">{{item.Title}}</span><span v-else>{{item.Class}}</span>

      </div>
      <div class="time">{{item.Time}}</div>
      <div class="times">{{item.Times}}</div>
      <div class="number">
        <span v-if="item.InShow=='新增'">+</span><span v-else>-</span>
        {{item.ToNumber}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    Data: {
      type: Array
    }
  },


  data() {
    return {
      listheight: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let listheight = document.documentElement.clientHeight - 60 - 180 - 50
      listheight = 'height:' + listheight + 'px'
      this.listheight = listheight
    }
  }
}
</script>

<style scoped lang="scss">
.UserData {
  width: 100%;
  overflow-y: auto;
  .data{
    width: 90%;
    height: 80px;
    border-radius: 15px;
    box-shadow: 0 1px 2px 1px rgba(255, 255,255, 0.5);
    background: linear-gradient(180deg, #21242e 70%, rgba(255, 255,255, 0.2) 100%);
    color: #fff;
    position: relative;
    margin: 0 auto;
    margin-bottom: 20px;
    .title{
      position: absolute;
      left: 20px;
      top: 10px;
      font-size: 19px;
      font-weight: bold;
    }
    .time{
      position: absolute;
      left: 20px;
      top: 40px;
      font-size: 13px;
      color: #787878;
    }
    .times{
      position: absolute;
      left: 20px;
      top: 57px;
      font-size: 13px;
      color: #787878;
    }
    .number{
      position: absolute;
      right: 20px;
      top: 30px;
      font-size: 18px;
      color: #776abe;
      font-weight: bold;
    }
  }
}
</style>