<template>
  <div class="UserInfo">
    <div class="avatar">
      <img :src="UserInfo.Avatar" v-if="UserInfo.Avatar"  alt=""/>
    </div>
    <div class="name">{{UserInfo.Name}}</div>
  </div>
</template>

<script>
export default {
  props: {
    UserInfo: {
      type: Object
    }
  },
  data() {
    return {

    }
  },

}
</script>

<style scoped lang="scss">
.UserInfo{
  width: 100%;
  height: 180px;
  position: relative;
  background-image: url("../../../assets/image/UserBg.png");
  background-size: 100% 100%;
  .avatar {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #d8d8d8;
    border-radius: 50%;
    left: 50%;
    margin-left: -40px;
    top: 30px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .name{
    position: absolute;
    top: 140px;
    color: #feffff;
    font-size: 13px;
    width: 100%;
    text-align: center;
  }
}
</style>